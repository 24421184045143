// Generated by Framer (2415018)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {EW__0noHR: {hover: true}};

const cycleOrder = ["EW__0noHR"];

const serializationHash = "framer-nvDG9"

const variantClassNames = {EW__0noHR: "framer-v-19rblc4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.6, ease: [0.7, 0, 0.3, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, AovXj56VB: link ?? props.AovXj56VB, WH98k6Eu3: icon ?? props.WH98k6Eu3 ?? {src: "https://framerusercontent.com/images/Meep5TtAM1CrFrfxR2UCpWrSes4.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;icon?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AovXj56VB, WH98k6Eu3, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "EW__0noHR", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-nvDG9", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={AovXj56VB}><Image {...restProps} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 32, intrinsicWidth: 32, pixelHeight: 32, pixelWidth: 32, sizes: "min(32px, 100vw)", ...toResponsiveImage(WH98k6Eu3)}} className={`${cx("framer-19rblc4", className)} framer-1lgmrsw`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"EW__0noHR"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"EW__0noHR-hover": {"data-framer-name": undefined, background: {alt: "", fit: "fit", intrinsicHeight: 32, intrinsicWidth: 32, pixelHeight: 32, pixelWidth: 32, src: "https://framerusercontent.com/images/ooaVTHD2x4gEzwZEAVbB2gbj9GA.svg"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-nvDG9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-nvDG9 .framer-1lgmrsw { display: block; }", ".framer-nvDG9 .framer-19rblc4 { cursor: pointer; height: 32px; overflow: hidden; position: relative; text-decoration: none; width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XfcaJ4bTK":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"AovXj56VB":"link","WH98k6Eu3":"icon"}
 * @framerImmutableVariables true
 */
const FramermJ1l4OSAo: React.ComponentType<Props> = withCSS(Component, css, "framer-nvDG9") as typeof Component;
export default FramermJ1l4OSAo;

FramermJ1l4OSAo.displayName = "social icon";

FramermJ1l4OSAo.defaultProps = {height: 32, width: 32};

addPropertyControls(FramermJ1l4OSAo, {AovXj56VB: {title: "Link", type: ControlType.Link}, WH98k6Eu3: {__defaultAssetReference: "data:framer/asset-reference,Meep5TtAM1CrFrfxR2UCpWrSes4.svg?originalFilename=linkedin.svg&preferredSize=auto", title: "icon", type: ControlType.ResponsiveImage}} as any)

addFonts(FramermJ1l4OSAo, [])